import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Section, Container } from "../../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const ThrThankyouPage = location => {
    const staticData = useStaticQuery(graphql`
        query {
            imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return(
        <Layout location={location} currentLocation={location.uri}>
            <SEO title="Thank You" description="" />
            <Helmet>
                <body className="bd-page" />
            </Helmet>
            <CoverMain
                // h1={data.wordPress.pageBy.title || "Image Properti"}
                img=""
                alt=""
                className="cover-md"
            >
                <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
            </CoverMain>
            <Section className="main-thankyou">
                <Container className="container-md">
                <h2>THANK YOU!</h2>
                <p>
                    Terima kasih telah tertarik dengan program kami. Anda akan dihubungi
                    oleh tim kami untuk program yang ditawarkan
                </p>
                </Container>
            </Section>
        </Layout>
    )
}
export default ThrThankyouPage