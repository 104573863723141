import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export const Breadcrumbs = ({ path, className, as }) => {
  const pathNames = typeof path === "string" ? path.split("/") : null

  const renderSlugName = (slug) => {
    const name = slug.split("-")
    switch (slug) {
      case "bsd-city":
        return "BSD City";
      case "annual-report":
        return "Annual Reports / Circulars";
      default:
        return name.join(" ");
    }
  }

  return (
    <ol className={`breadcrumb ${className}`}>
      {typeof path === "string"
        ? pathNames.map((item, index) => {
            if (index === 0) {
              return (
                <li className="breadcrumb-item" key={`path-home`}>
                  <Link to="/" className="fx-underline">
                    Home
                  </Link>
                </li>
              )
            } else if (index === pathNames.length - 1 && item !== "") {
              return (
                <li className="breadcrumb-item" key={`path-${item}`}>
                  {renderSlugName(item)}
                </li>
              )
            } else if (item === "") {
              return null
            } else {
              let slicedPath = pathNames.slice(0, index + 1)
              let url = slicedPath.join("/")

              return (
                <li className="breadcrumb-item" key={`path-${item}`}>
                  {as === "text" ? (
                    renderSlugName(item)
                  ) : (
                    <Link to={url} className="fx-underline">
                      {renderSlugName(item)}
                    </Link>
                  )}
                </li>
              )
            }
          })
        : null}
    </ol>
  )
}

Breadcrumbs.propTypes = {
  path: PropTypes.string,
}
