import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import YouTube from "react-youtube"

import { replaceUnicode } from "../utils/utils"

export const CoverMain = ({
  label,
  h1,
  h2,
  img,
  alt,
  text,
  btnText,
  btnLink,
  containerClassName,
  className,
  children,
  videoUrl,
}) => {
  const re = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i
  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      showinfo: 0,
      rel: 0,
      loop: 1,
      mute: 1,
      playlist: videoUrl && videoUrl.match(re)[7],
    },
  }

  return (
    <>
      {typeof img === "object" ? (
        <BackgroundImage
          Tag="section"
          className={`cover cover-main cover-full-top ${className}`}
          fluid={img}
          alt={alt}
        >
          <div className={`container ${containerClassName}`}>
            {children}
            <div className="cover-content">
              {label && <span className="cover-label">{label}</span>}{" "}
              {/* Sinarmas Land */}
              {h1 && <h1 className="cover-title">{replaceUnicode(h1)}</h1>}
              {h2 && <h2 className="cover-title">{replaceUnicode(h2)}</h2>}
              {text && <p className="cover-text">{text}</p>}
              {btnText && (
                <Link className="btn btn-primary reverse" to={btnLink}>
                  {btnText}
                </Link>
              )}
            </div>
          </div>
          <div className="bg-overlay bg-overlay-40"></div>
        </BackgroundImage>
      ) : (
        <section
          className={`cover cover-main cover-img cover-full-top ${className}`}
          alt={alt}
          style={{
            background: `url(${img}) no-repeat center`,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {videoUrl && (
            <div className="bg-video-wrap">
              <YouTube
                videoId={videoUrl && videoUrl.match(re)[7]}
                opts={videoOptions}
                className={'bg-video'}
              />
            </div>
          )}
          <div className={`container ${containerClassName}`}>
            {children}
            <div className="cover-content">
              {label && <span className="cover-label">{label}</span>}{" "}
              {/* Sinarmas Land */}
              {h1 && <h1 className="cover-title">{replaceUnicode(h1)}</h1>}
              {h2 && <h2 className="cover-title">{replaceUnicode(h2)}</h2>}
              {text && <p className="cover-text">{text}</p>}
              {btnText && (
                <Link className="btn btn-primary reverse" to={btnLink}>
                  {btnText}
                </Link>
              )}
            </div>
          </div>
          <div className="bg-overlay bg-overlay-40"></div>
        </section>
      )}
    </>
  )
}

CoverMain.propTypes = {
  label: PropTypes.string,
  h1: PropTypes.string,
  h2: PropTypes.string,
  img: PropTypes.any,
  alt: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  videoUrl: PropTypes.string,
}

CoverMain.defaultProps = {
  containerClassName: "",
  className: "",
}
